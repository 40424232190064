import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import GiteIcon from '@mui/icons-material/Gite';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MessageIcon from '@mui/icons-material/Message';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import { PATH_DASHBOARD } from '../../../routes/paths';


const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon:<Icon icon="ri:dashboard-line" /> },
      // { title: 'Subscription', path: PATH_DASHBOARD.dorm.dorm, icon: <Icon icon="material-symbols-light:subscriptions-rounded" /> },
      // { title: 'Video', path: PATH_DASHBOARD.element.element, icon: <Icon icon="octicon:organization-16" /> },
      // { title: 'Donation',  path: '/dashboard/rarity' , icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'User',  path: PATH_DASHBOARD.spell.spell , icon:<PersonIcon /> },
      { title: 'Host',  path: PATH_DASHBOARD.host.host , icon:<GiteIcon /> },
      { title: 'Facility',  path: PATH_DASHBOARD.facility.facility   , icon:<PeopleAltIcon /> },
      { title: 'Amenity',  path: PATH_DASHBOARD.amenity.amenity   , icon:<RamenDiningIcon /> },
      { title: 'Listing',  path: PATH_DASHBOARD.listing.listing   , icon:<FormatListBulletedIcon /> },
      { title: 'Booking',  path: PATH_DASHBOARD.booking.booking   , icon:<Icon icon="ri:dashboard-line" /> },
      { title: 'Message',  path: PATH_DASHBOARD.message.message   , icon:<MessageIcon /> },
      { title: 'Notification',  path: PATH_DASHBOARD.message.notification   , icon:<MarkChatUnreadIcon /> },
    ],
  },

];

export default navConfig;
