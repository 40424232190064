/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import axios from "axios";
import './index.css'
import { useNavigate } from "react-router";


const PaypalPlan = () => {

const navigation = useNavigate()
const [PlanData ,setPlanData] = useState()

const getPlan = ()=>{
  
  const token = localStorage.getItem('accessToken')
  axios.get('https://yallachaletapi.dev-sh.xyz/api/plan', {
     headers: {
       Authorization: `Bearer ${token}`,
     },
   }).then((res) => {
       setPlanData(res?.data?.plan)
 
   }).catch((err) => {
       console.error('Error:', err); 
   });
}
useEffect(()=>{
  getPlan()
},[])


console.log(PlanData,'PlanData')
  return (
    <div id="wrapper">

      <div className="content">
        {/*  section  */}
        <section className="gray-bg main-dashboard-sec" id="sec1">
        

            <div>
                <h1 style={{
                    fontSize:'20px',
                    fontWeight:'bolder'
                }}>Paypal Plan</h1>

                <div style={{
                  display:"flex",
                  justifyContent:'end'
                }}>

 <button onClick={()=>navigation('/dashboard/updateplan')} style={{
                    padding:'10px',
                    backgroundColor:'#6DBBFD',
                    color:'white',
                    cursor:'pointer',
                    width:'20%',
                    borderRadius:'5px',
                    border:'none'

                  }}>
                    Change Plan
                  </button>

                </div>



                <div className="plan-card">
      <div className="plan-header">
        <p>Name : {PlanData?.name}</p>
        <p>Description : {PlanData?.description}</p>
        <p>Status : {PlanData?.status}</p>
        <p>Interval Unit: {PlanData?.billing_cycles[0].frequency.interval_unit}</p>
        <p>Price : {PlanData?.billing_cycles[0].pricing_scheme.fixed_price.value}</p>

      </div>
      
    </div>



         


          </div>
        </section>
      </div>



    
    </div>
  );
};

export default PaypalPlan;
