/* eslint-disable react/self-closing-comp */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import styles from './style.module.css'
import axios from "axios";
import { useNavigate } from "react-router";

// eslint-disable-next-line arrow-body-style
const Updateplan = () => {
const [name, setname] = useState('');
const [description, setDescription] = useState('');
const [interval_unit, setIntervalUnit] = useState('Month');
const [price, setPrice] = useState('');
const navigation = useNavigate()

const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Convert price to number format
    const priceNumber = parseFloat(price);
  
    // Collect form data
    const formData = {
        name,
        description,
        interval_unit,
        price: priceNumber, // Price ko number format mein pass karein
    };
  
    // Log form data to console
  
  
  
    const token = localStorage.getItem('accessToken')
   axios.post('https://yallachaletapi.dev-sh.xyz/api/plan', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
        console.log('Response:', res.data); // Response ko console mein log karein ya further processing karein
        if(res.data){
            navigation('/dashboard/plan')
        }
        // Further logic after successful response
  
    }).catch((err) => {
        console.error('Error:', err); // Error ko console mein log karein
  
        // Handle error, display error message, etc.
  
    });
  };

  return (
    <div>
     <div className={styles.formContainer}>
       <form onSubmit={handleSubmit}>
           {/* First Row */}
           <div className={styles.formRow}>
               <div className={styles.formGroup}>
                   <label htmlFor="name"> Name:</label>
                   <input
                       type="text"
                       id="name"
                       value={name}
                       onChange={(e) => setname(e.target.value)}
                       className={styles.inputField}
                       required
                   />
               </div>
               <div className={styles.formGroup}>
                   <label htmlFor="description">Description:</label>
                   <textarea
                       id="description"
                       value={description}
                       onChange={(e) => setDescription(e.target.value)}
                       className={styles.textareaField}
                       required
                   ></textarea>
               </div>
           </div>

           {/* Second Row */}
           <div className={styles.formRow}>
               <div className={styles.formGroup}>
                   <label htmlFor="interval_unit">Interval Unit:</label>
                   <select
                   disabled={true}
                       id="interval_unit"
                       value={interval_unit}
                       onChange={(e) => setIntervalUnit(e.target.value)}
                       className={styles.selectField}
                       required
                   >
                       <option value="">Month</option>
                       {/* <option value="Day"> Day</option>
                       <option value="Month"> Month </option> */}
                   </select>
               </div>
               <div className={styles.formGroup}>
                   <label htmlFor="price">Price:</label>
                   <input
                       type="number"
                       id="price"
                       value={price}
                       onChange={(e) => setPrice(e.target.value)}
                       className={styles.inputField}
                       required
                   />
               </div>
           </div>

           {/* Centered Button */}
           <div className={styles.centeredButton}>
               <button type="submit" className={styles.btnSubmit}>Change</button>
           </div>
       </form>
   </div>



  <div>

   

  </div>

    </div>
  )
}

export default Updateplan
